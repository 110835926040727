/*
 *
 * Fonts
 *
 * Setup site fonts
 */


//google fonts
//  @import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700');

$font-path: "../fonts" !default;

/***********
 Impot Fonts
************/
@font-face {
    font-family: 'wavehaus';
    src: url('#{$font-path}/wavehaus-66book-webfont.woff2') format('woff2'),
         url('#{$font-path}/wavehaus-66book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'wavehaus';
    src: url('#{$font-path}/wavehaus-95semibold-webfont.woff2') format('woff2'),
         url('#{$font-path}/wavehaus-95semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'wavehaus';
    src: url('#{$font-path}/wavehaus-128bold-webfont.woff2') format('woff2'),
         url('#{$font-path}/wavehaus-128bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}


/**************
 Font Variables
***************/

$sans-serif: 'Arial Narrow', Arial, sans-serif;
$site-font: 'wavehaus', $sans-serif;