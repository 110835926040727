/*
 *
 *  Index
 *
 *  This is the default global styling for the site.
 *  The majority of the styling of the site should be kept within each component.
 */

//variables
@import "@styles/variables.scss";

//fonts
$font-path: "../fonts";
@import "@styles/fonts.scss";

//reset
body {
  margin: 0;
  padding: 0;
  color: $dark-text;
  font-family: $site-font;
  background: $body-background;
}

button {
  font-family: $site-font;
}

input {
  font-family: $site-font;
}

a {
  text-decoration: none;
  color: $link-text;
  font-weight: 500;
}
p {
  margin: 0 0 1.3em;
}

p,
li {
  font-size: 14px;
  line-height: 1.6;
  @media screen and (min-width: $tablet) {
    font-size: 16px;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

//typography

h1,
h2 {
  text-transform: uppercase;
  font-weight: 600;
}

h1 {
  font-weight: 600;
  font-size: 25px;
  margin: 0 0 $base-1;
  @media screen and (min-width: $tablet) {
    font-size: 35px;
  }
}
h2 {
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 0;
  @media screen and (min-width: $tablet) {
    font-size: 23px;
  }
}
h3 {
  margin: 0 0 $base-2;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  img {
    margin-right: $base-1;
  }
}

//nav
nav {
  ul {
    li {
      a {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

//scrollbars

/*
 *  STYLE 4
 */

*::-webkit-scrollbar-track {
  background-color: #eaeaea;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba($hopp-dark-blue, 0.3);
}

//forms
form {
  position: relative;
  section {
    h3 {
      font-size: 20px;
    }
  }
  div {
    p {
      margin: 8px 0;
      font-size: 14px;
      line-height: 1.4;
    }
    ol li {
      font-size: 14px;
      line-height: 1.4;
    }
  }
  select {
    // -webkit-appearance: none;
  }
  input,
  textarea,
  select {
    border-radius: 3px;
    border: 1px solid transparent;
    background: $white;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 18px;
    font-size: 16px;
    max-height: 57px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 15px;
    font-family: $site-font;
    letter-spacing: 0.5px;
    margin-top: 10px;
    display: block;
    @media screen and (min-width: $tablet) {
      padding: 16px 28px;
    }
    &[type="checkbox"] {
      display: inline-block;
      margin: auto;
      margin-right: $base-s;
      width: auto;
    }

    &:focus {
      border: 1px solid $focus-color;
      outline: none;
    }
  }
  textarea {
    line-height: 1.4;
  }
  .checkbox-wrapper {
    margin: $base-1 0;
    &.termsandconditions {
      margin: $base-4 0;
      label {
        font-weight: normal;
        color: $grey-out;
      }
    }
  }
  textarea {
    min-height: 150px;
  }

  .filepond {
    &--wrapper {
      margin-bottom: 20px;
      margin-top: 10px;
    }
    &--drip {
      background: $grey-background;
      opacity: 1;
      border: 1px dashed $soft-grey;
    }
    &--drop-label {
      label {
        text-transform: uppercase;
        letter-spacing: 1.65px;
        font-size: 14px;
        color: $dark-text;
        font-weight: 600;
      }
    }
    &--label-action {
      text-decoration: none;
      border-bottom: 1px solid $soft-grey;
      padding-bottom: 2px;
    }
  }
  .filepond--root .filepond--drop-label {
    min-height: 150px;
  }

  label {
    font-size: 11px;
    color: $grey-text;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .react-select-container {
    margin-top: 8px;
    margin-bottom: 15px;
    & div:first-of-type {
      border-color: transparent;
      border-radius: 3px;
      &:hover {
        border-color: hsl(0, 0%, 100%);
      }
    }
  }
  .react-select-parent {
    position: relative;
  }

  .description-container {
    padding-bottom: 30px;
  }

  .service-selectors {
    border-bottom: 1px solid $light-line;
    padding-top: 30px;
  }

  .supplierstyle {
    padding-top: 50px;
  }

  .dynamic-input {
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $base-1 0 0;
      h3 {
        margin: 0;
      }
    }

    &__item {
      display: grid;
      grid-template-columns: auto 1fr;
      label {
        margin-right: $base-1;
        margin-top: 2px;
      }

      &--faq {
        padding: 45px 0 32px;
        border-top: 1px solid $light-line;
        &:first-child {
          border: none;
          padding-top: $base-s;
        }
      }
    }
    &__remove {
      margin: $base-2 0 $base-1;
      label {
        margin-left: 5px;
      }
      button {
        background: none;
        border-radius: 100%;
        width: 22px;
        line-height: 16px;
        height: 22px;
        padding: 0 0 0 1px;
        box-shadow: 0 4px 10px -4px rgba(19, 23, 48, 0.29);
        border: 1px solid $hopp-dark-blue;
        color: $hopp-dark-blue;
        cursor: pointer;
        outline: none;
        transition: all 150ms ease-in-out;
        &:hover {
          background: $hopp-dark-blue;
          color: $white;
        }
      }
    }
  }
}

//global class to style account forms
.account-form-section {
  background: $dark-background;
  min-height: calc(100vh - 95px);
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    padding: $base-1;
    margin: $base-1;
    border: 1px solid $light-line;
    border-radius: 3px;
    box-shadow: 0 4px 10px -4px rgba(19, 23, 48, 0.29);
    background: $grey-background;
    @media screen and (min-width: $tablet) {
      padding: $base-2;
    }
    h2,
    h3 {
      text-align: center;
    }
  }
}

//swiper
.swiper-pagination-bullet {
  background: $hopp-pink;
}

.swiper-button {
  outline: none;
  background-size: 15px 30px;
  @media screen and (min-width: $tablet) {
    background-size: 20px 44px;
  }
}

.swiper-pagination-progressbar {
  background: darken($light-grey, 2%);
  .swiper-pagination-progressbar-fill {
    background: $soft-grey;
  }
}

.swiper-pagination {
  position: relative;
  padding-top: 15px;
}

.swiper-container,
.swiper-wrapper {
  z-index: auto;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  top: auto;
}

//slick
.slick-slide {
  &,
  div {
    outline: 0;
  }
}
.slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background-color: transparent;

  &.slick-disabled {
    opacity: 0.45;
    pointer-events: none;
    cursor: auto;
  }

  &.slick-next,
  &.slick-prev {
    outline: none;
    width: 27px;
    height: 44px;
    background-size: 15px 30px;
    @media screen and (min-width: $tablet) {
      background-size: 20px 44px;
    }
    background-position: center;
    background-repeat: no-repeat;
    z-index: 6;
  }

  &.slick-next {
    right: 10px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    &.slick-black {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    }
  }

  &.slick-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
    left: 10px;
    &.slick-black {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    }
  }
}

.slick-slider {
  &.black-arrows {
    .slick-next {
      right: 10px;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    }
    .slick-prev {
      left: 10px;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
    }
  }
}

/* Dots */
.slick-dots {
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  margin-top: 20px;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background: $hopp-pink;
  border-radius: 100%;
  content: "";
  text-align: center;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

//react dates
.SingleDatePicker {
  &,
  .DateInput,
  .SingleDatePickerInput {
    width: 100%;
    padding: 0;
  }
  input {
    margin: 0;
    border: none;
    padding: 16px 28px;
    font-size: 16px;
    font-weight: 500;
    color: $dark-text;
    &:focus {
      border: none;
    }
  }
  li {
    line-height: 1;
  }
  button {
    margin: 0;
  }

  .CalendarMonth_caption {
    padding-bottom: 40px;
  }
  .CalendarDay__selected {
    &,
    &:active,
    &:hover {
      background-color: $hopp-pink;
      border-color: $hopp-pink;
    }
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $hopp-pink;
  }
}
